import i18next from 'i18next';

export const getDocumentTypeTranslation = (documentType) => {
    // Vérification plus stricte du type
    if (!documentType || typeof documentType !== 'string') {
        console.warn(`Invalid documentType provided: ${documentType}`);
        return '';
    }
    
    // Format the key: lowercase and replace spaces and slashes with underscores
    const formattedKey = documentType
        .toLowerCase()
        .replace(/[/ ]/g, '_'); // Plus besoin d'échapper le slash
    
    const translationKey = `templates.documentType.${documentType}`;
    // Try with original key first (for backward compatibility)
    let translation = i18next.t(translationKey);
    
    // If no translation found, try with formatted key
    if (translation === translationKey) {
        const formattedTranslationKey = `templates.documentType.${formattedKey}`;
        translation = i18next.t(formattedTranslationKey);
        // If still no translation, return original
        if (translation === formattedTranslationKey) {
            return documentType;
        }
    }
    
    return translation;
};

export const getAttributeTranslation = (attribute) => {
    // Même vérification stricte pour attribute
    if (!attribute || typeof attribute !== 'string') {
        console.warn(`Invalid attribute provided: ${attribute}`);
        return '';
    }

    const formattedKey = attribute
        .toLowerCase()
        .replace(/[/ ]/g, '_'); // Plus besoin d'échapper le slash ici non plus
    
    const translationKey = `templates.attributes.${attribute}`;
    let translation = i18next.t(translationKey);
    
    if (translation === translationKey) {
        const formattedTranslationKey = `templates.attributes.${formattedKey}`;
        translation = i18next.t(formattedTranslationKey);
        if (translation === formattedTranslationKey) {
            return attribute;
        }
    }
    
    return translation;
};