import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Form, Button, Spinner, Alert } from 'react-bootstrap';

const AnalysisForm = ({ criteria, setCriteria, onSubmit, isProcessing, error }) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={onSubmit}>
      <Card>
        <Card.Body>
          <Form.Group>
            <Form.Label htmlFor="criteria">
              {t('pages.workflow.documentSection.requests.documentCriteria')}
            </Form.Label>
            <Form.Control
              id="criteria"
              as="textarea"
              value={criteria}
              onChange={(e) => setCriteria(e.target.value)}
              placeholder={t('pages.workflow.tryKeywords')}
              rows={4}
            />
          </Form.Group>

          {error && (
            <Alert variant="danger" className="mt-3">{error}</Alert>
          )}
        </Card.Body>
        <Card.Footer className="text-center">
          <Button 
            type="submit" 
            className="w-100"
            disabled={!criteria.trim() || isProcessing}
          >
            {isProcessing ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" className="me-2" />
                {t('pages.workflow.analyzingYourRequest')}
              </>
            ) : (
              t('pages.workflow.analyze')
            )}
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default AnalysisForm;