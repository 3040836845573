import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/Date';
import { TEAM_URL, IRL_URL, NOTE_ID_URL, DOC_URL } from '../../utils/urls';
import { DataRoomContext } from '../DisplayDataRoom';

function ActivityCard({ activity }) {
    const { t } = useTranslation();
    const dataroom_id = useContext(DataRoomContext);
    
    const getActivityIcon = () => {
        switch (activity.object_type) {
            case "FOLDER":
                return <i className="bi bi-folder2 activityIcon"></i>;
            case "FILE":
                return <i className="bi bi-file-text activityIcon"></i>;
            case "NOTE":
                return <i className="bi bi-journals activityIcon"></i>;
            case "IRL":
                return <i className="bi bi-card-checklist activityIcon"></i>;
            default:
                return <i className="bi bi-app activityIcon"></i>;
        }
    };

    const getEventTranslationKey = (eventTypeName) => {
        // Convert "File creation" to "file_creation"
        return eventTypeName
            .toLowerCase()
            .replace(' ', '_');
    };

    const parseDescription = (description, object_id) => {
        const replacements = {
            'folder': <Link key="document-link" to={DOC_URL.replace(':dataroomId', dataroom_id)}>
                {t('pages.activities.objects.folder')}
            </Link>,
            'file': <Link key="document-link" to={DOC_URL.replace(':dataroomId', dataroom_id)}>
                {t('pages.activities.objects.file')}
            </Link>,
            'note': <Link key="note-link" to={NOTE_ID_URL.replace(':dataroomId', dataroom_id).replace(':noteId', object_id)}>
                {t('pages.activities.objects.note')}
            </Link>,
            'ir': <Link key="ir-link" to={IRL_URL.replace(':dataroomId', dataroom_id)}>
                {t('pages.activities.objects.information_request')}
            </Link>
        };
        
        const eventKey = activity.event_type_name ? getEventTranslationKey(activity.event_type_name) : 'unknown';
        const translationKey = `pages.activities.descriptions.${eventKey}`;
        const translatedDescription = t(translationKey, { defaultValue: description });
        
        return translatedDescription.split(/(\[[^\]]+\])/).map((chunk, index) => {
            const match = chunk.match(/^\[([a-z]+)\]$/i);
            if (match && replacements[match[1].toLowerCase()]) {
                return React.cloneElement(replacements[match[1].toLowerCase()], { key: `link-${index}` });
            }
            return <span key={`span-${index}`}>{chunk}</span>;
        });
    };

    return (
        <tr className="activity-row">
            <td className="activity-event">
                {getActivityIcon()}
                <span>
                    {activity.event_type_name && 
                        t(`pages.activities.events.${getEventTranslationKey(activity.event_type_name)}`, {
                            defaultValue: activity.event_type_name
                        })
                    }
                </span>
            </td>
            <td className="activity-user">
                <Link to={TEAM_URL.replace(':dataroomId', dataroom_id).replace(':teamId', activity.triggered_by)}>
                    {activity.triggered_by_username}
                </Link>
            </td>
            <td className="activity-description">
                {parseDescription(activity.description, activity.object_id)}
            </td>
            <td className="activity-date">
                {formatDate(activity.created_at, t)}
            </td>
        </tr>
    );
}

export default ActivityCard;