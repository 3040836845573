import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getDocumentTypeTranslation } from '../../../utils/documentUtils';
import './DocumentItem.css';

const DocumentItem = ({ 
    name, 
    document_type_name,
    description,
    icon, 
    metadata, 
    actions, 
    onClick
}) => {
    const truncateName = (text) => {
        if (!text || text.length <= 20) return text;
        return `${text.substring(0, 15)}...${text.substring(text.length - 5)}`;
    };

    return (
        <div className="document-item">
            <div className="document-content">
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>
                            {description || name}
                            {metadata && <div className="mt-1">{metadata}</div>}
                        </Tooltip>
                    }
                >
                    <span className={`document-name ${onClick ? 'clickable' : ''}`} onClick={onClick}>
                        <i className={`bi ${icon}`}></i>
                        {truncateName(name)} <span className="document-type-name mx-2">{getDocumentTypeTranslation(document_type_name)}</span>
                    </span>
                </OverlayTrigger>
            </div>
            <div className="document-actions">
                {actions}
            </div>
        </div>
    );
};

export default DocumentItem;