import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, InputGroup, Badge, ListGroup, Button, Spinner } from 'react-bootstrap';
import { formatDate } from '../../../../../utils/Date';
import { getDocumentTypeTranslation } from '../../../../../utils/documentUtils';

const ExistingDocumentsList = ({ documents = [], onSelect, loading = false, onFileOpen }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDocs, setSelectedDocs] = useState(new Set());

  const filteredDocuments = documents.filter(doc => {
    const searchTerm = searchQuery.toLowerCase();
    
    return (
      doc?.reference_name?.toLowerCase()?.includes(searchTerm) ||
      doc?.reference_description?.toLowerCase()?.includes(searchTerm) ||
      getDocumentTypeTranslation(doc?.file?.document_type_name)?.toLowerCase()?.includes(searchTerm) ||
      doc?.file?.name?.toLowerCase()?.includes(searchTerm)
    );
  });

  const handleDocumentClick = (doc, event) => {
    event.preventDefault();
    const newSelected = new Set(selectedDocs);
    
    if (newSelected.has(doc.id)) {
      newSelected.delete(doc.id);
    } else {
      newSelected.add(doc.id);
    }
    
    setSelectedDocs(newSelected);
  };

  const handleLinkSelected = () => {
    const selectedDocuments = documents.filter(doc => selectedDocs.has(doc.id));
    onSelect(selectedDocuments);
    setSelectedDocs(new Set());
  };

  const handleFileOpen = (e, doc) => {
    e.stopPropagation(); // Empêcher la sélection du document lors de l'ouverture
    if (doc.file?.presigned_url) {
      onFileOpen(doc.file.presigned_url);
    }
  };

  return (
    <div className="d-flex flex-column gap-3">
      {/* Search and Link button container - inchangé */}
      <div className="d-flex gap-3 align-items-start">
        <InputGroup>
          <InputGroup.Text>
            <i className="bi bi-search" />
          </InputGroup.Text>
          <Form.Control
            placeholder={t('pages.workflow.documentSection.requests.searchDocuments')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </InputGroup>

        <Button 
          variant="primary"
          onClick={handleLinkSelected}
          disabled={selectedDocs.size === 0}
          className="d-flex align-items-center gap-1 text-nowrap"
        >
          <i className="bi bi-link-45deg" />
          {t('pages.workflow.documentSection.requests.linkSelected', {
            count: selectedDocs.size || 0
          })}
        </Button>
      </div>

      {/* Documents list */}
      <div className="border rounded d-flex" style={{ overflowY: 'auto', minHeight: "200px" }}>
        {loading ? (
          <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <Spinner animation="border" />
            <div className="mt-2">{t('loading')}</div>
          </div>
        ) : filteredDocuments.length === 0 ? (
          <div className="p-3 text-center text-muted flex-grow-1">
            {t('pages.workflow.documentSection.requests.noDocumentsFound')}
          </div>
        ) : (
          <ListGroup variant="flush" className="w-100">
            {filteredDocuments.map((doc) => (
              <ListGroup.Item 
                key={doc.id}
                action
                onClick={(e) => handleDocumentClick(doc, e)}
                className={`py-2 ${selectedDocs.has(doc.id) ? 'bg-light' : ''}`}
              >
                <div className="d-flex align-items-start gap-3 px-3">
                  <div style={{ width: '40px', paddingTop: '4px' }}>
                    <Form.Check
                      type="checkbox"
                      checked={selectedDocs.has(doc.id)}
                      onChange={() => {}}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>

                  <div style={{ width: '35%' }}>
                    {doc.reference_name ? (
                      <>
                        <div className="fw-semibold">{doc.reference_name}</div>
                        <div className="text-muted small">{doc.reference_description}</div>
                      </>
                    ) : (
                      <div className="fw-semibold">
                        {doc.reference_description || t('pages.workflow.documentSection.requests.untitled')}
                      </div>
                    )}
                  </div>

                  <div style={{ width: '25%' }}>
                    {doc.file ? (
                      <div className="text-muted">
                        <i className="bi bi-tag me-2" />
                        {getDocumentTypeTranslation(doc.file.document_type_name)}
                      </div>
                    ) : (
                      <div className="text-black-25 fst-italic">
                        <i className="bi bi-cloud-upload me-2" />
                        {t('pages.workflow.documentSection.requests.pendingUpload')}
                      </div>
                    )}
                  </div>

                  <div style={{ width: '25%' }} className="text-break">
                        {doc.file && (
                        <div 
                            onClick={(e) => handleFileOpen(e, doc)}
                            className="text-primary file-link" 
                            role="button"
                            title={t('general.view')}
                            style={{ cursor: 'pointer' }}
                        >
                            <i className="bi bi-file-earmark me-2" />
                            {doc.file.name}
                        </div>
                        )}
                  </div>

                  <div style={{ width: '15%' }} className="text-end text-muted small">
                    {doc.created_at && (
                      <div>
                        <i className="bi bi-calendar me-2" />
                        {formatDate(doc.created_at, t)}
                      </div>
                    )}
                  </div>
                </div>

                {doc.controls?.length > 0 && (
                  <div className="mt-2 ms-5">
                    <div className="d-flex align-items-center gap-2 text-muted small">
                      <i className="bi bi-tags" />
                      <span>Contrôles associés:</span>
                      <div className="d-flex flex-wrap gap-1">
                        {doc.controls.map(control => (
                          <Badge 
                            key={control.id}
                            bg="light"
                            text="secondary"
                            className="border"
                          >
                            {control.name}
                          </Badge>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>
    </div>
  );
};

export default ExistingDocumentsList;