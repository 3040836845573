import React, { createContext, useContext, useState, useEffect, useRef, useMemo } from 'react';
import { useUser } from '../../general/UserProvider';
import { DataRoomContext } from '../../dataroom/DisplayDataRoom';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

const PermissionsContext = createContext();

export function usePermissions() {
    return useContext(PermissionsContext);
}

export function PermissionsProvider({ children }) {
    const { token, username } = useUser();
    const dataroomId = useContext(DataRoomContext);
    const [permissions, setPermissions] = useState({});
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    
    // Cache global des permissions
    const permissionsCache = useRef({});

    useEffect(() => {
        let isMounted = true;
        const cacheKey = `${dataroomId}-${username}`;

        if (token && username && dataroomId) {
            // Vérifier le cache
            if (permissionsCache.current[cacheKey]) {
                setPermissions(permissionsCache.current[cacheKey]);
                setIsLoading(false);
                return;
            }

            const loadPermissions = async () => {
                setIsLoading(true);
                try {
                    const response = await api.get(API_ROUTES.PERMISSIONS_BY_USER_AND_DATAROOM(dataroomId), {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    if (isMounted) {
                        permissionsCache.current[cacheKey] = response.data;
                        console.log(response.data);
                        setPermissions(response.data);
                        setError(null);
                    }
                } catch (error) {
                    if (isMounted) {
                        setError(error.message);
                    }
                } finally {
                    if (isMounted) {
                        setIsLoading(false);
                    }
                }
            };

            loadPermissions();
        }

        return () => {
            isMounted = false;
        };
    }, [token, username, dataroomId]);

    const value = useMemo(() => ({
        permissions,
        error,
        isLoading
    }), [permissions, error, isLoading]);

    return (
        <PermissionsContext.Provider value={value}>
            {children}
        </PermissionsContext.Provider>
    );
}